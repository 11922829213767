import Alpine from "alpinejs";

Alpine.data("serviceInvoiceValidation", () => {
  return {
    lines: [
      {
        id: null,
        price: 0,
        quantity: 1,
        description: "",
        _destroy: false,
        isValid: true,
      },
    ],

    init() {
      this.focusFirstLineQuantity();
    },

    focusFirstLineQuantity() {
      this.$nextTick(() => {
        const firstQuantityInput = document.querySelector(
          `#service_invoice_line_items_attributes_0_quantity`,
        );
        if (firstQuantityInput) {
          firstQuantityInput.focus();
        }
      });
    },

    addNewLine() {
      this.lines.push({
        id: null,
        price: 0,
        quantity: 1,
        description: "",
        _destroy: false,
        isValid: true,
      });
      this.$nextTick(() => {
        const lastLineIndex = this.lines.length - 1;
        const quantityInput = document.querySelector(
          `#service_invoice_line_items_attributes_${lastLineIndex}_quantity`,
        );
        if (quantityInput) {
          quantityInput.focus();
        }
      });
    },

    removeLine(index) {
      const line = this.lines[index];
      if (line.id) {
        this.lines[index]._destroy = true;
      } else {
        this.lines.splice(index, 1);
      }
    },

    total() {
      const totalValue = this.lines.reduce(
        (acc, cur) => acc + parseFloat(cur.price) * parseFloat(cur.quantity),
        0,
      );
      return isNaN(totalValue) ? "€ -.--" : `€ ${totalValue.toFixed(2)}`;
    },

    linePrice(line) {
      return isNaN(line.price * line.quantity)
        ? "€ -.--"
        : `€ ${(line.price * line.quantity).toFixed(2)}`;
    },

    vat() {
      const vatValue = this.lines.reduce(
        (acc, cur) => acc + cur.price * cur.quantity * 0.21,
        0,
      );
      return isNaN(vatValue) ? `€ -.--` : `€ ${vatValue.toFixed(2)}`;
    },

    isNumeric(value) {
      return /^\d+(\.\d{1,2})?$/.test(value);
    },

    priceValid(price) {
      return this.isNumeric(price) && parseFloat(price) >= 0;
    },

    quantityValid(quantity) {
      return this.isNumeric(quantity) && parseFloat(quantity) > 0;
    },

    validateAllFields() {
      const allValid = this.lines.every((line) => {
        const priceValid = this.priceValid(line.price);
        const quantityValid = this.quantityValid(line.quantity);
        line.isValid = priceValid && quantityValid;
        return line.isValid;
      });

      return allValid;
    },

    submitDisabled() {
      return !this.validateAllFields();
    },

    submitFormOnCtrlEnter(event) {
      if (
        (event.metaKey || event.ctrlKey) &&
        event.key === "Enter" &&
        !this.submitDisabled()
      ) {
        event.preventDefault();
        this.submitForm();
      }
    },
    submitForm() {
      this.$refs.invoiceForm.submit();
    },
  };
});
