// This is the Alpine.js component for the process_return page fields validation.

import Alpine from "alpinejs";

document.addEventListener("alpine:init", () => {
  Alpine.data("process_return_form_validation", () => ({
    destinationsSp: [],
    deviceId: null,
    graded: null,
    destinationSgid: null,
    destination_loading: false,
    errorMessage: null,

    isDestinationValid() {
      return (
        this.destinationSgid !== null &&
        this.destinationSgid !== "" &&
        this.destinationsSp.some(
          (destination) => destination[1] === this.destinationSgid,
        )
      );
    },

    isGradeValid() {
      return this.graded;
    },

    isDeviceCardFilled() {
      return (
        this.isDestinationValid(this.destinationsSp) && this.isGradeValid()
      );
    },

    isAllDeviceCardsFilled() {
      const index = this.allCardsStatus.findIndex(
        (card) => card.deviceId === this.deviceId,
      );
      const filledStatus = this.isDestinationValid(this.destinationsSp);

      if (index !== -1) {
        // DeviceId found, update its filled status
        this.allCardsStatus[index].filled = filledStatus;
      } else {
        // DeviceId not found, add a new entry
        this.allCardsStatus.push({
          deviceId: this.deviceId,
          filled: filledStatus,
        });
      }
    },

    async saveChange() {
      try {
        this.destination_loading = true;
        const response = await fetch(`/devices/${this.deviceId}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
              .content,
          },
          body: JSON.stringify({
            device: { destination_sgid: this.destinationSgid },
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        await response.json();
      } catch (error) {
        console.error("There was a problem with the fetch operation: ", error);
        this.errorMessage =
          "There was a problem saving your changes. Please try again later.";
      } finally {
        this.destination_loading = false;
      }
    },
  }));
});
