import Alpine from "alpinejs";
import { LeaderLine } from "leader-line";

Alpine.directive(
  "leader-line",
  (el, { value, modifiers, expression }, { evaluate, effect, cleanup }) => {
    // this directive draws a leader-line from element to an element with `x-ref` reference `value`
    // the expression is passed as a third argument to the leader-line constructor
    const source = el;
    const target = evaluate('$refs["' + value + '"]');
    if (source && target) {
      const ll = new LeaderLine(
        source,
        evaluate('$refs["' + value + '"]'),
        evaluate(expression) || {},
      );

      // after creating the line, move it into the x-data root element so scrolling works
      const wrapper = evaluate("$refs.leader_line_wrapper");
      wrapper.appendChild(document.querySelector("body > .leader-line"));

      wrapper.style.transform = "none";
      const rectWrapper = wrapper.getBoundingClientRect();

      // Move to the origin of coordinates as the document
      wrapper.style.transform =
        "translate(" +
        (rectWrapper.left + scrollX) * -1 +
        "px, " +
        (rectWrapper.top + scrollY) * -1 +
        "px)";
      ll.position();
    }
  },
);
