/* from https://quatauta.gitlab.io/post/2020/december/06/times-dates-in-web-browser-timezone-with-ruby-on-rails-6.0/ */
function setTimezoneCookie() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let expires = new Date();

  expires.setTime(expires.getTime() + 60 * 60 * 24);
  expires = expires.toGMTString();

  document.cookie = "timezone=" + timezone + "; Path=/; SameSite=Lax; Secure";
}

export default setTimezoneCookie();
