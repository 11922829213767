// This is the Alpine.js component for email field fetching and validation to avoid create duplicates users in the database.

import Alpine from "alpinejs";

document.addEventListener("alpine:init", () => {
  Alpine.data("emailChecker", () => ({
    users: [],
    userSgid: undefined,
    name: undefined,
    email: undefined,

    async checkEmail(email) {
      // Check if the email is too short, we won't query the backend yet
      if (email.length <= 4) {
        this.users = [];
        return;
      }

      try {
        const response = await fetch(
          `/users/check_email?email=${encodeURIComponent(email)}`,
        );
        const data = await response.json();

        if (data.users) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          // if I put in a full email address (i.e. this.email matches the regex)
          // and I get only one response, we can automatically select that one
          if (data.users.length === 1 && emailRegex.test(this.email)) {
            // automatically apply this one
            this.select(data.users[0]);
          } else {
            this.users = data.users;
          }
        } else {
          this.users = [];
        }
      } catch (error) {
        console.error("Error checking email:", error);
      }
    },

    select(user) {
      this.name = user.name;
      this.email = user.email;
      this.userSgid = user.sgid;
    },

    deselect() {
      this.name = undefined;
      this.email = undefined;
      this.userSgid = undefined;
    },
  }));
});
