import Alpine from "alpinejs";

Alpine.data("deviceRenewals", () => ({
  search: new URL(window.location.href).searchParams.get("q") || "",
  selectedDeviceSlugs: [],
  selectAll: false,

  init() {
    this.$watch("search", (search) => {
      const url = new URL(window.location.href);

      if (search) {
        url.searchParams.set("q", search);
      } else {
        url.searchParams.delete("q");
      }

      if (url.toString() !== window.location.href) {
        history.replaceState(null, "", url.toString());
      }
    });

    this.$watch("selectedDeviceSlugs", (newVal) => {
      const deviceSlugs = this.$el.querySelectorAll(
        '[type="checkbox"][x-on\\:click^="toggleSelection"]',
      ).length;
      this.selectAll = newVal.length === deviceSlugs;
    });
  },

  toggleSelection(slug) {
    const index = this.selectedDeviceSlugs.indexOf(slug);
    if (index > -1) {
      this.selectedDeviceSlugs.splice(index, 1);
    } else {
      this.selectedDeviceSlugs.push(slug);
    }
  },

  toggleAll(deviceSlugs) {
    this.selectAll = !this.selectAll;
    this.selectedDeviceSlugs = this.selectAll ? deviceSlugs.slice() : [];
  },
}));
