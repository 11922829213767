// This file is used to fetch the location addresses of the service providers only for afterservice admin or service provider
import Alpine from "alpinejs";

Alpine.data("spLocationAddresses", () => {
  // Get the service provider id from the hidden input field if user is service provider or edit existing location with service provider
  let serviceProviderId = null;
  const serviceProviderField = document.getElementById("service_provider_id");
  if (serviceProviderField) {
    serviceProviderId = serviceProviderField.value;
  }

  return {
    sp_location_addresses: [],
    // If user is super admin, set the service provider id to the select service provider field value
    service_provider_id: null,

    init() {
      if (serviceProviderId !== null) {
        this.updateLocationAddresses(serviceProviderId);
      } else {
        this.$watch("service_provider_id", (value) => {
          if (value !== null) {
            this.updateLocationAddresses(value);
          }
        });
      }
    },

    updateLocationAddresses(spId) {
      fetch(`/locations/addresses/${spId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.sp_location_addresses = data;
        })
        .catch((error) =>
          console.error(
            "There was a problem with the fetch operation: ",
            error,
          ),
        );
    },
  };
});
