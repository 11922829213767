// This file is used to download CSV files of index pages from the server
import Alpine from "alpinejs";

Alpine.data("downloadCsv", () => {
  return {
    generating: false, // State to manage button disabled status

    generateCsv() {
      this.generating = true;
      const currentUrl = new URL(window.location.href); // Get the current URL
      const url = new URL(`${currentUrl.pathname}.csv`, window.location.origin);
      url.searchParams.append("search", this.search);

      // Directly navigate to the URL to trigger the download
      window.location.href = url.toString();
      this.generating = false;
    },
  };
});
