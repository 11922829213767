import Alpine from "alpinejs";
import { initDestinationTailSelect } from "./destination_select_utils";

const GROUPS = {
  USER: "User",
  LOCATION: "Location",
};

Alpine.data("destination_search", (customerId, initialDestinations, selectedDestination = null) => ({
  destination: "",
  select: null,
  customerId,
  initialDestinations,
  selectedDestination: null, // ["User" || "Location", name, sgid]

  // Initialize component
  init() {
    this.initTailSelect();
    this.loadInitialOptions();
    this.setupSearchInputListener();
  },

  // Initialize Tail-Select
  initTailSelect() {
    const destinationSelectElement = document.querySelector(
      'select[id*="destination_sgid"]',
    );
    this.select = initDestinationTailSelect(destinationSelectElement);
  },

  // Listen for input event on the search box and debounce the search
  setupSearchInputListener() {
    const searchInput = document.querySelector(".search-input");
    if (searchInput) {
      searchInput.addEventListener(
        "input",
        Alpine.debounce((event) => {
          const query = event.target.value;
          this.searchAndUpdateOptions(query);
        }, 200),
      );
    }
  },

  loadInitialOptions() {
    // Add the initial destinations passed in from the server
    const data = this.cloneData(this.initialDestinations);
    this.addDropdownOptions(data);
    this.checkForMoreResults(data);
    this.addInitiallySelectedOption();
  },

  addInitiallySelectedOption() {
    if (selectedDestination) {
      const [group, value, key] = selectedDestination;
      this.select.options.add(key, value, group, true, false, '', true); // will be ignored if already in set
      this.select.options.select(key, group); // so we also select the option explicitly
    }
  },

  // Fetch destination options from server
  async fetchDestinations(query) {
    const response = await fetch(
      `/api/customers/${this.customerId}/search_destinations?query=${encodeURIComponent(query)}`,
    );
    return await response.json();
  },

  async searchAndUpdateOptions(query) {
    // If the query is empty, reset the more results indicator
    if (query.length < 1) {
      this.resetMoreResultsIndicator();
      return;
    }

    const data = await this.fetchDestinations(query);

    // Add the new options to the select
    this.addDropdownOptions(data);

    // Check if there are more results to show
    this.checkForMoreResults(data);

    // Reapply the search query to ensure highlighting remains
    this.select.query(query || "");
  },

  // Add the top 10 options for each group
  addDropdownOptions(data) {
    const userOptions = this.getTopOptionsByGroup(data, GROUPS.USER);
    const locationOptions = this.getTopOptionsByGroup(data, GROUPS.LOCATION);

    const formattedOptions = [...userOptions, ...locationOptions].map(
      ([key, item]) => ({
        key,
        value: item.value,
        text: item.value,
        group: item.group,
        disabled: item.disabled || false,
      }),
    );

    // Add the options to the select dropdown
    this.select.options.add(formattedOptions);
  },

  getTopOptionsByGroup(data, group) {
    return Object.entries(data)
      .filter(([_, item]) => item.group === group)
      .slice(0, 10);
  },

  // Check if there are more results to show
  checkForMoreResults(data) {
    Object.values(GROUPS).forEach((group) => {
      const count = this.getOptionCountByGroup(data, group);
      this.updateMoreResultsOption(count, group);
    });
  },

  // Get count of options for a group
  getOptionCountByGroup(data, group) {
    return Object.entries(data).filter(([_, item]) => item.group === group)
      .length;
  },

  // Update more results indicator
  updateMoreResultsOption(count, group) {
    const moreResultsKey = `more_${group.toLowerCase()}`;

    this.select.options.remove(moreResultsKey, group, true);

    if (count > 10) {
      this.select.options.add([
        {
          key: moreResultsKey,
          value: "...",
          group,
          disabled: true,
        },
      ]);
    }
  },

  // Edge case: If the user deletes the search query, reset the more results indicator
  resetMoreResultsIndicator() {
    const data = this.cloneData(this.initialDestinations);
    this.checkForMoreResults(data);
  },

  // Utility to deep clone data (avoid mutation)
  cloneData(data) {
    return JSON.parse(JSON.stringify(data));
  },
}));
