import Alpine from "alpinejs";

Alpine.data("deviceForm", (device, plannedLifecycles) => ({
  device,
  customer_id: device.customer_id || "NaN",
  show_sim_field: false,
  show_edit_model: false,
  device_type: device.device_type || null,
  model_group_id: device.model_group_id ? device.model_group_id.toString() : "",
  show_device_type: device.model_group_id === null,
  destination_sgid: device.destination_sgid,
  matching_devices: [],
  planned_lifecycles: plannedLifecycles,

  device_select_html_args: {
    "x-init": function () {
      const el = this.$el;
      this.model_group_id = this.device.model_group_id
        ? this.device.model_group_id.toString()
        : "";
      this.device_type = this.selected_device_type(el);
      this.show_sim_field = this.selected_device_has_sim(el);
      this.show_device_type = !el.value;
    },
  },

  updateModelGroupId(event) {
    const el = event.target;
    this.model_group_id = el.value ? el.value.toString() : "";
    this.device.model_group_id = this.model_group_id;
    this.device_type = this.selected_device_type(el);
    this.device.device_type = this.device_type;
    this.show_sim_field = this.selected_device_has_sim(el);
    this.show_device_type = !el.value;
    if (this.show_device_type) {
      this.hasSim();
    }
    this.getPlannedLifecycle();
  },

  hasSim() {
    if (this.device_type === "smartphone" || this.device_type === "tablet") {
      this.show_sim_field = true;
    } else {
      this.show_sim_field = false;
    }
    this.device.device_type = this.device_type;
  },

  selected_device_has_sim(el) {
    const optgroup = el[el.selectedIndex]?.parentElement?.label;
    return optgroup?.indexOf("phone") >= 0 || optgroup?.indexOf("tablet") >= 0;
  },

  selected_device_type(el) {
    const optgroup = el[el.selectedIndex]?.parentElement?.label;
    if (optgroup) {
      return optgroup.slice(optgroup.indexOf(" - ") + 3);
    } else {
      return this.device_type;
    }
  },

  getPlannedLifecycle() {
    return this.device.planned_lifecycle
      ? this.device.planned_lifecycle.toString()
      : this.planned_lifecycles[this.device_type] || "";
  },

  validateNumbers: function (v, numberType) {
    let numberRegex;
    switch (numberType) {
      case "imei":
        numberRegex = /^[0-9]{15,16}$/;
        break;
      case "iccid":
        numberRegex = /^[0-9]{18,22}$/;
        break;
    }
    return numberRegex.test(v);
  },

  handleDeviceClick(option) {
    this.device = option;
    this.model_group_id = option.model_group_id
      ? option.model_group_id.toString()
      : "";

    window.setTimeout(() => {
      const el = this.$root.querySelector(".tail_select");
      window.tail_select(el).reload();
      this.show_sim_field = this.selected_device_has_sim(el);
      this.show_device_type = !el.value;
    });
  },
}));
