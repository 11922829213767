import Alpine from "alpinejs";

/**
 * This file contains all client slide validation JS. Validation is triggered by
 * the x-validate and x-unique directives that are defined here. Required /
 * optional fields are still implemented in the main file. That could move here
 * at some point.
 */

// Magics
Alpine.magic("value", (el) => {
  return el.value;
});
Alpine.magic("formFieldId", (el) => {
  return el.closest(".form_field_container").querySelector("[name]").id;
});

// x-data scopes
// in this entire thing, `this` is the current x-data context of the form
Alpine.data("client_side_validation", () => ({
  fields: {},
  uniqueness_error_msg: "Must be unique.", // this is overriden by x-unique
  previous_focus_id: null,
  register_field() {
    if (!Object.hasOwn(this.fields, this.$el.id)) {
      this.fields[this.$el.id] = {
        value: "",
        errors: {},
        next_focus_id: null,
      };
    }
  },
  // the following methods are used by x-unique
  update_value() {
    this.fields[this.$el.id].value = this.$value;

    // now update uniquenes errors
    this.set_uniqueness_error();
  },
  get_all_values() {
    const vals = [];
    Object.keys(this.fields).forEach((k) => {
      vals.push(this.fields[k].value);
    });
    return vals;
  },
  set_uniqueness_error() {
    const vals = this.get_all_values();
    Object.keys(this.fields).forEach((k) => {
      const v = this.fields[k].value;
      if (v === "" || vals.indexOf(v) === vals.lastIndexOf(v)) {
        this.fields[k].errors.uniqueness = null;
      } else {
        this.fields[k].errors.uniqueness = this.uniqueness_error_msg;
      }
    });
  },
  // the following methods are used by x-validate
  set_error(key, msg) {
    this.fields[this.$el.id].errors[key] = msg;
  },
  unset_error(key) {
    this.fields[this.$el.id].errors[key] = null;
  },
  // the following method is used by the error span. Cannot use this.$el here
  // because that would be the error span, not the input
  print_errors_for(id) {
    if (!this.fields[id]) {
      return "";
    }
    const messages = [];
    Object.keys(this.fields[id].errors).forEach((k) => {
      messages.push(this.fields[id].errors[k]);
    });
    return messages.join(" ");
  },
  // check whether an element is currently accessible by the user (or hidden
  // by x-show on the form_field_container)
  is_visible(id) {
    const el = document.getElementById(id).closest(".form_field_container");
    return el && window.getComputedStyle(el).display !== "none";
  },
  // bind this to elements that should be in a focusing cycle where enter
  // works as tab (x-bind="enter_is_tab")
  enter_is_tab: {
    "x-init"() {
      this.register_field();
      const id = this.$el.id;
      if (this.previous_focus_id) {
        this.fields[this.previous_focus_id].next_focus_id = id;
      }
      this.previous_focus_id = id;
    },
    "@keydown.enter.prevent.stop"() {
      let id = this.$el.id;
      // the loop makes it work also if one field is removed from DOM or
      // if it is hidden by an x-show directive
      while (id) {
        id = this.fields[id].next_focus_id;

        if (id == null) {
          // last field passed, submit the form
          this.$el.closest("form").submit();
        }

        const nextEl = document.getElementById(id);
        if (nextEl && this.is_visible(id)) {
          nextEl.focus();
          break;
        }
      }
    },
    "@keydown.ctrl.enter"() {
      // this ensures ctrl+enter in the field submits the form
      this.$el.closest("form").submit();
    },
    "@keydown.window.once.ctrl.enter"() {
      // this ensures ctrl+enter anywhere in the form outside of fields
      // submits the form
      this.$el.closest("form").submit();
    },
  },
}));

// Directives
Alpine.directive(
  "validate",
  (
    el,
    { key, modifiers, expression },
    { evaluate, evaluateLater, cleanup },
  ) => {
    // the expression of this directive should be a JSON object that features
    // a test function that is given the value and must return true/false, and
    // an error msg.
    const allowBlank = modifiers.includes("allow-blank");
    evaluate("register_field()");
    const validation = evaluate(expression);
    const unsetError = evaluateLater(`unset_error('${key}')`);
    const setError = evaluateLater(`set_error('${key}','${validation.msg}')`);

    const handler = (e) => {
      const valid =
        (allowBlank && el.value === "") || validation.test(el.value);
      if (valid) {
        // remove the error while typing
        unsetError();
      }
      if (!valid && e.type === "blur") {
        // only add it when leaving this input
        setError();
      }
    };

    el.addEventListener("blur", handler);
    el.addEventListener("keyup", handler);

    handler({ type: "blur" }); // run it once at init

    cleanup(() => {
      el.removeEventListener("blur", handler);
      el.removeEventListener("keyup", handler);
    });
  },
);

Alpine.directive(
  "unique",
  (el, { expression }, { evaluate, evaluateLater, cleanup }) => {
    // this directive gives a message if the user accidentally repeats the same
    // value multiple times. It is only checked against other inputs that have
    // this directive enabled in the same form
    evaluate("register_field()");
    evaluate(`uniqueness_error_msg = '${expression}';`);
    const updateValue = evaluateLater("update_value($value)");

    const handler = () => {
      updateValue();
    };

    el.addEventListener("keyup", handler);
    // selectionchange seems to be the event triggered by the x-model binding
    el.addEventListener("selectionchange", handler);

    updateValue(); // run it once at init

    cleanup(() => {
      el.removeEventListener("keyup", handler);
      el.removeEventListener("selectionchange", handler);
    });
  },
);

Alpine.directive("strip-leading", (el, { value }, { cleanup }) => {
  // this directive strips a leading `value` (case insensitive) from a field, if the string is empty up to that point
  // (that way you can add it manually afterwards if desired)
  const handler = (e) => {
    if (e.target.value.toUpperCase() === value.toUpperCase()) {
      e.target.value = "";
    }
  };
  el.addEventListener("keyup", handler);

  cleanup(() => {
    el.removeEventListener("keyup", handler);
  });
});
