import Alpine from "alpinejs";

Alpine.data(
  "orderLimits",
  (
    existingDevices = [],
    deviceRules = [],
    isAdmin = false,
    forceDeviceLimits = false,
  ) => ({
    // Stores the count of each product type (existing and to be added)
    productCounts: {},
    // Device rules to be enforced
    deviceRules: [
      ...deviceRules,
      { type: "MinMaxDeviceRulePart", max: 10, device_types: ["accessory"] },
    ],
    // Ignore device limits for the order
    ignoreLimits: forceDeviceLimits ? false : isAdmin,

    // Initialize the product counts with existing devices
    init() {
      Object.entries(existingDevices).forEach(([type, count]) => {
        this.productCounts[type] = count;
      });
    },

    /* ================= Order Functions ================= */

    // Update item count based on user input from a numeric field
    updateItemCount(products, event, previousCount) {
      const quantity = this.getQuantityToAdd(products, event, previousCount);

      products.forEach((products) => {
        this.productCounts[products.type] += quantity;
      });
      return previousCount + quantity;
    },

    // Toggle the quantity of products in the order
    toggleItemInOrder(products, isChecked, currentCount) {
      const change = isChecked ? 1 : -currentCount;

      products.forEach((product) => {
        this.productCounts[product.type] += change;
      });
    },

    // Check if the input should be disabled
    shouldDisableInput(products, isChecked) {
      if (this.ignoreLimits) return false;
      if (!this.areAllProductModelsAllowed(products)) return true;

      return (
        this.getMaxAllowedQuantityForRules(products, this.productCounts) <= 0 &&
        !isChecked
      );
    },

    // Check if the user can order more than 1 of a product
    canOrderMultiple(products) {
      if (this.ignoreLimits) return true;
      if (!this.areAllProductModelsAllowed(products)) return false;

      return this.getMaxAllowedQuantityForRules(products, existingDevices) >= 2;
    },

    /* ================= Helper Functions ================= */

    // Get the quantity to add based on user input
    getQuantityToAdd(products, event, previousCount) {
      const inputQuantity = parseInt(event.target.value, 10) || 0;

      // Calculate the change in quantity
      const change = inputQuantity - previousCount;

      // Get the maximum quantity allowed to add based on existing device counts
      const maxAllowedQuantity = this.getMaxAllowedQuantityForRules(
        products,
        this.productCounts,
      );

      // Adjust count by considering the maximum allowed
      return change > 0
        ? Math.min(change, maxAllowedQuantity) // increase
        : change; // decrease
    },

    /* ================= Min-Max Rule ================= */

    getMaxAllowedQuantityForRules(products, counts) {
      if (this.ignoreLimits) return Infinity;

      const productTypes = products.map((product) => product.type);

      // If not all product types are covered by device rules, return 0
      if (!this.areAllProductTypesCovered(productTypes)) return 0;

      const relevantRules = this.getRelevantRules(productTypes);

      const maxQuantities = this.calculateMaxQuantities(
        relevantRules,
        products,
        counts,
      );

      return Math.max(Math.min(...maxQuantities), 0);
    },

    // Check if all product types are covered by the rules
    areAllProductTypesCovered(productTypes) {
      return productTypes.every((type) =>
        this.deviceRules.some(
          (rule) =>
            rule.type === "MinMaxDeviceRulePart" &&
            rule.device_types.includes(type),
        ),
      );
    },

    // Get relevant rules for the given product types
    getRelevantRules(productTypes) {
      return this.deviceRules.filter(
        (rule) =>
          rule.type === "MinMaxDeviceRulePart" &&
          rule.device_types?.some((type) => productTypes.includes(type)),
      );
    },

    // Calculate max allowed quantities for each rule
    calculateMaxQuantities(relevantRules, products, counts) {
      return relevantRules.map((rule) => {
        // Count the total number of devices for the rule
        const totalCount = rule.device_types.reduce(
          (sum, type) => sum + (counts[type] || 0),
          0,
        );
        // Count the number of relevant products for this rule
        const relevantProductCount = products.filter((product) =>
          rule.device_types.includes(product.type),
        ).length;

        return Math.floor((rule.max - totalCount) / relevantProductCount);
      });
    },

    /* ================= Model Inclusion Rule ================= */

    // Check if all models are allowed for a set of products based on device rules
    areAllProductModelsAllowed(products) {
      return products.every((product) =>
        this.isProductModelAllowed(product.model_group),
      );
    },

    // Check if a specific product model group is allowed based on the rules
    isProductModelAllowed(modelGroup) {
      if (modelGroup === "accessory") return true;
      const inclusionRules = this.deviceRules.filter(
        (rule) => rule.type === "ModelGroupInclusionDeviceRulePart",
      );
      if (inclusionRules.length === 0) return true;

      // if a rule exists the product must be included in the list
      return inclusionRules[0].inclusions.includes(modelGroup);
    },
  }),
);
