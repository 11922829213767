import Alpine from "alpinejs";

Alpine.data("tracking_code_validation", () => {
  const lastCarrier =
    document.getElementById("motion_form").dataset.lastUsedCarrier;

  return {
    selected_carrier: lastCarrier || "",
    return_selected_carrier: "",
    validateTrackingCode: function (v, selectedCarrier) {
      const carrierSpecificRegex = /^[a-zA-Z0-9]{6,40}$/;

      return carrierSpecificRegex.test(v);
    },
  };
});
