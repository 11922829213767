// Custom search function for tail_select to include "more" options
export function initDestinationTailSelect(destinationSelectElement) {
  if (!destinationSelectElement) return;

  return window.tail_select(destinationSelectElement, {
    search: true,
    searchConfig: function (searchText, item) {
      // Always include "..." options
      const moreResultsKeys = ["more_location", "more_user"];
      if (moreResultsKeys.includes(item.value)) {
        return true;
      }

      if (!searchText || searchText.length === 0) return false;
      const value = item.text ? item.text.toLowerCase() : "";
      const search = searchText.toLowerCase();
      return value.includes(search);
    },
  });
}
