import Alpine from "alpinejs";

Alpine.data("autocompleteComponent", () => ({
  isShowing: false,
  searchQuery: "",
  street: "",
  streetNumber: "",
  addition: "",
  zip: "",
  city: "",
  state: "",
  countryCode: "NL",
  suggestions: [],
  focusedItemIndex: -1,
  clearButtonVisible: false,
  abortController: null,

  async fetchSuggestions(allowedCountries, apiKey) {
    // Abort the previous request
    if (this.abortController) {
      this.abortController.abort();
    }

    // Initialize a new AbortController
    this.abortController = new AbortController();

    if (this.searchQuery.length > 2) {
      try {
        const response = await fetch(
          `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(this.searchQuery)}&filter=countrycode:${allowedCountries}&format=json&limit=5&apiKey=${apiKey}`,
          { signal: this.abortController.signal },
        );
        const data = await response.json();
        this.focusedItemIndex = -1;
        // Update the `suggestions` model with the fetched data
        this.suggestions = data.results || [];
      } catch (error) {
        // Skip the error if it's an abort error
        if (error.name !== "AbortError") {
          console.error("Error:", error);
        }
      }
    } else {
      this.suggestions = [];
    }

    this.updateClearButtonVisibility();
  },

  updateClearButtonVisibility() {
    // Check if any of the fields have a value
    if (
      this.searchQuery !== "" ||
      this.street !== "" ||
      this.streetNumber !== "" ||
      this.addition !== "" ||
      this.zip !== "" ||
      this.city !== "" ||
      this.state !== "" ||
      this.countryCode !== "NL"
    )
      this.clearButtonVisible = true;
    else this.clearButtonVisible = false;
  },

  closeDropDownList() {
    this.suggestions = [];
    this.focusedItemIndex = -1;
  },

  addNew() {
    this.isShowing = !this.isShowing;
    this.address_sgid = null;
    if (!this.isShowing) {
      this.clearAddressFields();
    } else {
      this.$nextTick(() => this.$refs.searchField.focus());
    }
  },

  clearForm() {
    this.closeDropDownList();
    this.clearAddressFields();
    this.updateClearButtonVisibility();
    this.$refs.searchField.focus();
  },

  clearAddressFields() {
    this.searchQuery = "";
    this.street = "";
    this.streetNumber = "";
    this.addition = "";
    this.zip = "";
    this.city = "";
    this.state = "";
    this.countryCode = "NL";
  },

  focusItem(index) {
    this.focusedItemIndex = index;
  },

  focusNextItem() {
    if (this.focusedItemIndex < this.suggestions.length - 1) {
      this.focusedItemIndex++;
    } else {
      this.focusedItemIndex = 0; // Loop back to the first item
    }
  },

  focusPreviousItem() {
    if (this.focusedItemIndex > 0) {
      this.focusedItemIndex--;
    } else {
      this.focusedItemIndex = this.suggestions.length - 1; // Loop back to the last item
    }
  },

  selectFocusedItem() {
    if (this.suggestions.length > 0) {
      const selectedItem = this.suggestions[this.focusedItemIndex];
      this.selectSuggestion(selectedItem);
    } else {
      this.$refs.streetField.focus();
    }
  },

  selectSuggestion(selectedItem) {
    this.fillInAddressFields(selectedItem);
    this.closeDropDownList();

    if (!selectedItem?.street) {
      this.$refs.streetField.focus();
    } else if (!selectedItem?.housenumber) {
      this.$refs.streetNumberField.focus();
    } else {
      this.$refs.additionField.focus();
    }
  },

  fillInAddressFields(suggestion) {
    if (!suggestion) {
      return;
    }

    this.searchQuery = suggestion.formatted || "";
    this.street = suggestion.street || "";
    this.streetNumber = suggestion.housenumber || "";
    this.addition = "";
    this.zip = suggestion.postcode || "";
    this.city = suggestion.city || "";
    this.state = suggestion.state || "";
    this.countryCode = suggestion.country_code.toUpperCase() || "";
  },
}));
